import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get, post } from "../services/api";
import { getObject, setObject } from "./store_helpers";
import { rootState } from "../types/store/state";

const lightModeGraph = ["#00a6b5", "#020684", "#4caf50", "#7238f3", "#FFB627", "#FE5F55"];
const darkModeGraph = ["#00e9ff", "#FFB627", "#913aff", "#4caf50", "#FE5F55", "#E3D8F1"];

const initialFavoriteCompanies: Set<string> = new Set(getObject("favorite-companies"));
const initialFavoriteServices: Set<string> = new Set(getObject("favorite-services"));

export interface PreferencesModule {
  namespaced: boolean;
  state: () => PreferencesState;
  getters: GetterTree<PreferencesState, rootState>;
  actions: ActionTree<PreferencesState, rootState>;
  mutations: MutationTree<PreferencesState>;
}

export interface CnoVnoMode {
  [key: string]: any;
  active: boolean;
  beams: boolean;
  satellites: boolean;
  teleports: boolean;
}

export interface PreferencesState {
  mapMode: "3d" | "2d" | null;
  darkMode: boolean;
  language: string;
  startPage: string | null;
  favoriteCompanies: Set<string>;
  favoriteServices: Set<string>;
  savePreferencesError: boolean;
  cnoVnoMode: CnoVnoMode;
}

async function setPreferences(username: string, preferences: PreferencesState) {
  var preferencesObject = {
    mapMode: preferences.mapMode,
    darkMode: preferences.darkMode,
    language: preferences.language,
    startPage: preferences.startPage,
    favoriteCompanies: Array.from(preferences.favoriteCompanies),
    favoriteServices: Array.from(preferences.favoriteServices),
    cnoVnoMode: preferences.cnoVnoMode,
  };
  var data = await post(`myapi/user/preferences/${username}`, { data: preferencesObject });
  if (data?.data) {
    return true;
  }
  console.log("setPreferences Error:", data);
  return null;
}

const preferences_module: PreferencesModule = {
  namespaced: true,
  state: () => ({
    mapMode: "3d",
    darkMode: false,
    language: "en-US",
    startPage: "Home", // mapview, performance, servicereport, ipslareport, cases, change_requests
    favoriteCompanies: initialFavoriteCompanies,
    favoriteServices: initialFavoriteServices,
    savePreferencesError: false,
    cnoVnoMode: {
      active: false,
      beams: false,
      satellites: false,
      teleports: false,
    },
  }),

  getters: {
    mapMode(state) {
      return state.mapMode;
    },
    darkMode(state) {
      return state.darkMode;
    },
    language(state) {
      return state.language;
    },
    startPage(state) {
      return state.startPage;
    },
    graphColors(state) {
      return state.darkMode ? darkModeGraph : lightModeGraph;
    },
    savePreferencesError(state) {
      return state.savePreferencesError;
    },
    cnoVnoMode(state) {
      return state.cnoVnoMode;
    },
  },
  actions: {
    fetchPreferences({ commit }, username) {
      return new Promise((resolve, reject) => {
        get(`myapi/user/preferences/${username}`)
          .then(({ data }) => {
            if (data?.preferences) {
              commit("updatePreferences", JSON.parse(data.preferences));
              resolve(JSON.parse(data.preferences));
            }
            resolve(null);
          })
          .catch((e) => {
            console.log("error fetching preferences: ", e);
            reject(e);
          });
      });
    },
    loadPreferences({ commit }) {
      // from localStorage
      commit("prefsFromLocalStorage");
    },
    clearPreferencesError({ commit }) {
      commit("resetPreferencesErorr");
    },
  },
  mutations: {
    resetPreferencesError: (state) => {
      state.savePreferencesError = false;
    },
    prefsFromLocalStorage: (state) => {
      var mapMode = localStorage.getItem("map-mode") as "3d" | "2d" | null;
      if (mapMode !== null) {
        state.mapMode = mapMode;
      }
      var darkMode = localStorage.getItem("dark-mode");
      if (darkMode !== null) {
        state.darkMode = darkMode == "true" ? true : false;
      }
      var language = localStorage.getItem("language");
      if (language !== null) {
        state.language = language;
      }
      var startPage = localStorage.getItem("start-page");
      if (startPage !== null) {
        state.startPage = startPage;
      }
      var favoriteCompanies = getObject<Set<string>>("favorite-companies");
      state.favoriteCompanies = new Set(favoriteCompanies);
      var favoriteServices = getObject<Set<string>>("favorite-services");
      state.favoriteServices = new Set(favoriteServices);
      var cnoVnoMode = getObject("cno-vno-mode");
      if (cnoVnoMode) {
        state.cnoVnoMode = { ...state.cnoVnoMode, ...cnoVnoMode };
      }
    },
    updatePreferences: (state, preferences) => {
      if (preferences?.mapMode) {
        state.mapMode = preferences.mapMode;
        localStorage.setItem("map-mode", preferences.mapMode);
      }
      if (preferences?.darkMode) {
        state.darkMode = preferences.darkMode;
        localStorage.setItem("dark-mode", preferences.darkMode);
      }
      if (preferences?.language) {
        state.language = preferences.language;
        localStorage.setItem("language", preferences.language);
      }
      if (preferences?.startPage) {
        state.startPage = preferences.startPage;
        localStorage.setItem("start-page", preferences.startPage);
      }
      if (preferences?.favoriteCompanies) {
        state.favoriteCompanies = new Set(preferences.favoriteCompanies);
        setObject("favorite-companies", preferences.favoriteCompanies);
      }
      if (preferences?.favoriteServices) {
        state.favoriteServices = new Set(preferences.favoriteServices);
        setObject("favorite-services", preferences.favoriteServices);
      }
      if (preferences?.cnoVnoMode) {
        state.cnoVnoMode = preferences.cnoVnoMode;
        setObject("cno-vno-mode", preferences.cnoVnoMode);
      }
    },
    addFavorite: async (state, values) => {
      state.favoriteCompanies.add(values.company.company_id);
      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        setObject("favorite-companies", [...state.favoriteCompanies]);
      }
    },
    removeFavorite: async (state, values) => {
      state.favoriteCompanies.delete(values.company.company_id);
      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        setObject("favorite-companies", [...state.favoriteCompanies]);
      }
    },
    addFavoriteService: async (state, values) => {
      state.favoriteServices.add(values.service_sys_id);
      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        setObject("favorite-services", [...state.favoriteServices]);
      }
    },
    removeFavoriteService: async (state, values) => {
      state.favoriteServices.delete(values.service_sys_id);

      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        setObject("favorite-services", [...state.favoriteServices]);
      }
    },
    updateMapMode: async (state, values) => {
      state.mapMode = values.mapMode;
      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        localStorage.setItem("map-mode", state.mapMode ?? "3d");
      }
    },
    updateDarkMode: async (state, values) => {
      state.darkMode = values.darkMode;
      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        localStorage.setItem("dark-mode", String(state.darkMode));
      }
    },
    updateLanguage: async (state, values) => {
      state.language = values.language;
      if (values.username) {
        // skip saving to DB if language is changed at login screen
        await setPreferences(values.username, state);
      }
      localStorage.setItem("language", state.language);
    },
    updateStartPage: async (state, values) => {
      state.startPage = values.startPage;
      await setPreferences(values.username, state);
      localStorage.setItem("start-page", state.startPage ?? "Home");
    },
    updateCnoVnoMode: async (state, values) => {
      state.cnoVnoMode = { ...state.cnoVnoMode, ...values.cnoVnoMode };
      var s = await setPreferences(values.username, state);
      if (s === null) {
        state.savePreferencesError = true;
      } else {
        setObject("cno-vno-mode", state.cnoVnoMode);
      }
    },
    nullPreferences: (state) => {
      state.favoriteCompanies = new Set();
      state.mapMode = null;
      state.startPage = null;
      // don't remove language from localstorage so the login screen can use it
      // don't remove dark-mode from localstorage so the login screen can use it
      localStorage.removeItem("map-mode");
      localStorage.removeItem("start-page");
      localStorage.removeItem("favorite-companies");
      localStorage.removeItem("favorite-services");
      localStorage.removeItem("cno-vno-mode");
    },
  },
};
export default preferences_module;
