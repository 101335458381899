import httpClient from "./httpClient";
import { AxiosRequestConfig } from "axios";

export { get, post, put, patch, Delete };

var get = (api: string, config?: AxiosRequestConfig) => {
  return httpClient.get(`${import.meta.env.VITE_API_URL}/${api}`, config);
};

var post = (api: string, data?: Record<string, unknown>, config?: AxiosRequestConfig) => {
  return httpClient.post(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

var put = (api: string, data?: Record<string, unknown>, config?: AxiosRequestConfig) => {
  return httpClient.put(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

var patch = (api: string, data?: Record<string, unknown>, config?: AxiosRequestConfig) => {
  return httpClient.patch(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

var Delete = (api: string, config?: AxiosRequestConfig) => {
  return httpClient.delete(`${import.meta.env.VITE_API_URL}/${api}`, config);
};
